export default {
  components: {
    hotVideoInline: () => import('~/components/Molecules/VideoInline/VideoInline.vue'),
  },

  data() {
    return {}
  },

  props: {
    video: Object,
    testimony: Object,
    cardType: {
      type: String,
      default: 'video',
    },
  },
}
