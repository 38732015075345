
// import LazyHydrate from 'vue-lazy-hydration'
import hotOrganicTestimony from '~/components/Organisms/Organic/Testimony/Testimony.vue'

export default {
  components: {
    hotOrganicTestimony,

    // LazyHydrate,
    hotHeroClub: () => import('~/components/Organisms/HeroClub/HeroClub.vue'),
    hotOrganicBenefits: () => import('~/components/Organisms/Organic/Benefits/Benefits.vue'),
    OrganicModal: () => import('~/components/Molecules/Organic/Modal/Modal.vue'),
    hotOrganicRefund: () => import('~/components/Organisms/Organic/Refund/Refund.vue'),
    hotOrganicAdvantages: () => import('~/components/Organisms/Organic/Advantages/Advantages.vue'),
    hotOrganicTutorials: () => import('~/components/Organisms/Organic/Tutorials/Tutorials.vue'),
    hotOrganicEvolution: () => import('~/components/Organisms/Organic/Evolution/Evolution.vue'),
    hotEasyUse: () => import('~/components/Organisms/Organic/EasyUse/EasyUse.vue'),
    hotOrganicFaq: () => import('~/components/Organisms/Organic/Faq/Faq.vue'),
  },
}
