import CardCustom from '~/components/Molecules/Organic/CardCustom/CardCustom.vue'
import device from '~/mixins/device.js'

export default {
  mixins: [device],

  components: {
    hotCarousel: () => import('~/components/Molecules/Carousel/Carousel.vue'),
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    subtitle: {
      type: String,
    },

    description: {
      type: String,
    },

    items: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      cardCustom: CardCustom,
      swiperOptions: {
        allowTouchMove: true,
        normalizeSlideIndex: false,
        centeredSlides: false,
        slidesPerView: 1,

        breakpoints: {
          1280: {
            slidesPerView: 1.5,
            spaceBetween: 45,
          },
          768: {
            slidesPerView: 1.2,
            spaceBetween: 45,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          el: '.swiper-navigation',
          clickable: true,
        },
      },
    }
  },
}
